export const dizhi='江西省赣州市赣州经济技术开发区金凤数创园D栋903- 2'
export const gongsi='江西主赣道科技发展有限公司'
export const gongsi2='Copyright © 2024 江西主赣道科技发展有限公司'
export const dianhua='0797-8082858'

export const mixins = {
    data() {
      return {
        gongsi:gongsi,
        gongsi2:gongsi2,
        dianhua: dianhua,
        dizhi: dizhi,
        youxiang:'1318827509@qq.com',
        qq:'1318827509',
        // 首页底部
        // 赣公网安备 36070202000237号
        beianhao1: "",
        // 赣ICP备19012024号-1
        beianhao2: "| 赣ICP备19012024号-1",
        // 增值电信业务经营许可证 赣B2-20190161
        beianhao3: "| 增值电信业务经营许可证 赣B2-20190161",

      };
    },
  };